// src/components/Scene.js

import React, { useRef, useState } from 'react';
import {
  Text,
  OrbitControls,
  Sparkles,
  Stars,
  Float,
} from '@react-three/drei';
import { useFrame } from '@react-three/fiber';
import {
  MeshPhysicalMaterial,
  Mesh,
  IcosahedronGeometry,
  Color,
  Group,
} from 'three';
import { EffectComposer, Bloom } from '@react-three/postprocessing';
import { BlendFunction } from 'postprocessing';

function Scene() {
  const group = useRef();
  const [clicks, setClicks] = useState([]);

  // Rotate the group for animation
  useFrame(({ clock }) => {
    if (group.current) {
      group.current.rotation.y = 0.1 * clock.getElapsedTime();
    }
  });

  // Define materials
  const goldMaterial = new MeshPhysicalMaterial({
    color: 0xffd700,
    metalness: 1,
    roughness: 0.2,
    clearcoat: 1,
    clearcoatRoughness: 0,
  });

  const pinkMaterial = new MeshPhysicalMaterial({
    color: '#ff69b4',
    metalness: 0.5,
    roughness: 0.1,
    clearcoat: 1,
    clearcoatRoughness: 0,
  });

  // Emissive material for the glowing musical notes
  const noteMaterial = new MeshPhysicalMaterial({
    color: '#ffffff',
    emissive: new Color('#ffd700'),
    emissiveIntensity: 1.5,
    metalness: 0.5,
    roughness: 0.1,
    clearcoat: 1,
    clearcoatRoughness: 0,
  });

  function handlePointerDown(event) {
    const { x, y, z } = event.point;
    setClicks([...clicks, { position: [x, y, z], key: Math.random() }]);
  }

  // Musical note symbols
  const musicalNotes = ['♪', '♫', '♬', '♩'];

  // Positions for the musical notes and dance poses
  const positions = [
    [3, -2, 0],
    [-3, -2, 0],
    [2, 2, 0],
    [-2, 2, 0],
    [0, 0, 3],
    [0, 0, -3],
  ];

  return (
    <>
      {/* Lighting */}
      <ambientLight intensity={0.5} />
      <pointLight position={[10, 10, 10]} intensity={1} />
      <pointLight position={[-10, -10, -10]} intensity={1} />

      {/* Background Effects */}
      <Stars
        radius={100}
        depth={50}
        count={5000}
        factor={4}
        saturation={0}
        fade
      />

      {/* Sparkles */}
      <Sparkles count={100} size={1.5} scale={[10, 10, 10]} speed={1} />

      {/* Rotating Text Group */}
      <group ref={group}>
        {/* "HAPPY BIRTHDAY" Text */}
        <Float speed={2} rotationIntensity={1} floatIntensity={2}>
          <Text
            position={[0, 1, 0]}
            fontSize={1}
            letterSpacing={-0.05}
            color="#FFD700"
            anchorX="center"
            anchorY="middle"
            outlineWidth={0.02}
            outlineColor="#FFFFFF"
            maxWidth={10}
            textAlign="center"
          >
            HAPPY BIRTHDAY
          </Text>
        </Float>

        {/* "AMULYA" Text */}
        <Float speed={2} rotationIntensity={1} floatIntensity={2}>
          <Text
            position={[0, -1.5, 0]}
            fontSize={0.8}
            letterSpacing={-0.05}
            color="#FF69B4"
            anchorX="center"
            anchorY="middle"
            outlineWidth={0.02}
            outlineColor="#FFFFFF"
            maxWidth={10}
            textAlign="center"
          >
            AMULYA
          </Text>
        </Float>

        {/* Floating Musical Notes */}
        {positions.map((pos, index) => (
          <Float
            key={index}
            speed={1 + Math.random() * 2}
            rotationIntensity={1}
            floatIntensity={1}
            floatingRange={[-0.5, 0.5]}
          >
            <Text
              position={pos}
              fontSize={0.5}
              color="#FFD700"
              anchorX="center"
              anchorY="middle"
              outlineWidth={0.01}
              outlineColor="#FFFFFF"
              maxWidth={10}
              textAlign="center"
            >
              {musicalNotes[index % musicalNotes.length]}
            </Text>
          </Float>
        ))}

        {/* Abstract Dance Poses */}
        {positions.map((pos, index) => (
          <Float
            key={`dance-${index}`}
            speed={1 + Math.random() * 2}
            rotationIntensity={1}
            floatIntensity={1}
            floatingRange={[-0.5, 0.5]}
          >
            <mesh
              position={[pos[0], pos[1], pos[2] + 1]}
              scale={[0.5, 1, 0.5]}
              rotation={[0, Math.PI / 4, 0]}
            >
              <icosahedronGeometry args={[0.5, 0]} />
              <meshPhysicalMaterial
                color="#ff69b4"
                emissive={new Color('#ff69b4')}
                emissiveIntensity={0.5}
                metalness={0.5}
                roughness={0.1}
                clearcoat={1}
                clearcoatRoughness={0}
              />
            </mesh>
          </Float>
        ))}
      </group>

      {/* Particle Explosions on Click */}
      {clicks.map(({ position, key }) => (
        <Sparkles
          key={key}
          count={50}
          size={1}
          scale={1}
          position={position}
          speed={1}
          color={'#ffffff'}
        />
      ))}

      {/* Controls */}
      <OrbitControls
        enableZoom={false}
        onPointerDown={handlePointerDown}
        enablePan={false}
      />

      {/* Post-processing Effects */}
      <EffectComposer>
        <Bloom
          blendFunction={BlendFunction.ADD}
          intensity={2.5}
          width={300}
          height={300}
          kernelSize={5}
          luminanceThreshold={0.1}
          luminanceSmoothing={0.9}
        />
      </EffectComposer>
    </>
  );
}

export default Scene;
