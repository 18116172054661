// src/App.js

import React, { useEffect } from 'react';
import { Canvas } from '@react-three/fiber';
import Scene from './components/Scene';
import { colors } from './theme';
import './styles.css';
// import useSound from 'use-sound'; // Optional: Uncomment if you have an audio file

function App() {
  const gradientColors = colors.backgroundGradient.join(', ');

  // Optional: Uncomment if you have an audio file
  // const [play] = useSound('/audio/background-music.mp3', { volume: 0.5 });
  // useEffect(() => {
  //   play();
  // }, [play]);

  return (
    <div
      className="App"
      style={{ '--bg-gradient': gradientColors }}
    >
      <Canvas
        camera={{ position: [0, 0, 10], fov: 60 }}
        style={{ background: '#000' }}
      >
        <Scene />
      </Canvas>
    </div>
  );
}

export default App;
